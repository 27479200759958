
import { Options, Vue } from 'vue-class-component'
import api from '@/api'
import { toDate, toMoney } from '../utils/common'
import { Toast, Notify, Dialog } from 'vant'
@Options({
  components: {}
})
export default class Home extends Vue {
  loading = false
  toDate = toDate;
  toMoney = toMoney;

  regist: any = {};

  saleNo: any = '';

  couponId = ''
  couponInfo = {}
  ckcoupon (item: any) {
    console.log(item.id)
    if (parseInt(item.status) === 0) {
      return
    }
    this.couponInfo = item
    this.couponId = item.id
    this.couponsList = false
    this.getRegist()
  }

  disCkcoupon () {
    this.couponsList = false
    this.couponId = ''
    this.couponInfo = {}
    this.getRegist()
  }

  coupons: any = [];

  couponsList = false;

  chosenCoupon = -1;

  coupon: any = {
    couponlist: [],
    discount: ''
  };

  // eslint-disable-next-line camelcase
  cbo_coupon = false;

  // 获取订单信息
  getRegist () {
    this.loading = true
    api
      .SUREBIDEERINFO({ sale_no: this.$route.query.order_code, coupon_id: this.cbo_coupon ? this.couponId : '' })
      .then((res: any) => {
        this.loading = false
        this.regist = res
        // 如果只有优惠券
        if (this.coupon.couponlist.length && !this.coupon.discount) {
          this.cbo_coupon = true
        }
        this.coupons = res.coupon.couponlist
      }).catch((err) => {
        this.loading = false
        Dialog.alert({
          // eslint-disable-next-line comma-dangle
          message: err.msg,
        }).then(() => {
          // on close
          if (err.data.redirecturl) {
            this.$router.replace({
              path: 'pay',
              query: { ...this.$route.query }
            })
          }
        })
      })
  }

  RegistConfirm () {
    api.SOURCE({ sale_no: this.$route.query.order_code }).then((res: any) => {
      this.$router.push({
        name: 'pay',
        query: { ...this.$route.query }
      })
    }).catch(err => {
      Dialog.alert({
        // eslint-disable-next-line comma-dangle
        message: err.msg,
      }).then(() => {
        // on close

      })
    })
  }

  onChange = (index: number) => {
    this.couponsList = false
    this.chosenCoupon = index
  };

  created () {
    this.saleNo = this.$route.query.order_code
    this.getRegist()
  }
}
