import dayjs from 'dayjs'
/*
 * 日期转换
 * 2020年4月17日
 * 周祥
 */
export const toDate = (value: string | number, format = 'YYYY-MM-DD HH:mm:ss') => {
  if (!value) {
    return '-'
  }
  return dayjs(value).format(format)
}

/*
 * 金额格式转换 【拒绝浮点型bug和四舍五入】
 * 2020年4月21日
 * 周祥
 */
export const toMoney = (value: string | number, length = 2) => {
  if (!value) {
    return parseFloat('0').toFixed(length).toString()
  }
  let v = ''
  let x = parseFloat('0').toFixed(length).toString().substr(2)
  if (typeof value === 'number') {
    value = value.toString()
  }
  const valArr = value.split('.')
  v = valArr[0]
  if (valArr.length === 2 && valArr[1]) {
    x = valArr[1]
    if (x.length > length) {
      x = x.substr(0, length)
    }
    if (x.length < length) {
      x = x + parseFloat('0').toFixed(length - x.length).toString().substr(2)
    }
    if (x.length === 1 && parseInt(x) > 0) {
      x = x + '0'
    }
  }
  if (v.indexOf(',') === -1) {
    v = v.replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
  }
  return v + '.' + x
}
